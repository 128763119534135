import { getDefaultContext } from './accountNumberLookupReportContext';
import { SET_ACCOUNT_NUMBER_LOOKUP_REPORT_INFO } from './accountNumberLookupReportActionTypes';
import { RESET_ACCOUNT_NUMBER_LOOKUP_CONTEXT } from './accountNumberLookupReportActionTypes';


const accountNumberLookupReportReducer = (state, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_ACCOUNT_NUMBER_LOOKUP_REPORT_INFO:
      return {
        ...state,
        accountNumberLookupReportInfo: {
          ...state.accountNumberLookupReportInfo,
          ...payload
        }
      };
    case RESET_ACCOUNT_NUMBER_LOOKUP_CONTEXT:
      return{
        ...getDefaultContext()
      }
    default:
      return state;
  }
};

export { accountNumberLookupReportReducer as default };
