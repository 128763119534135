import React, { useEffect, useState, useRef } from 'react';
import { Box, Divider, Drawer, FormGroup, Grid, Stack, TablePagination, Tooltip } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import Button from '../formFields/Button';
import { FormValue, ListLabel, UnknownLabel } from '../styledComponets/Labels';
import { MainTitle, ReportMessage } from '../styledComponets/Titles';
import useRequest from '../../utils/hooks/useRequest';
import { getUserAffiliatesApi } from "../../api/affiliateStoreApi";

import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '../formFields/CheckBox';
import SearchControl from '../formFields/SearchControl';
import DeselectButton from '../formFields/DeselectButton';
import { useContext } from 'react';
import accountNumberLookupReportContext from '../../context/accountOrderReportContext/accountNumberLookupReportContext';
import { getAccountNumberLookupApi, downloadAccountNumberLookupReportApi } from '../../api/reports';
import { useMemo } from 'react';


function AccountNumberLookupReport(props) {
    const affiliatePerPageLimit = 10;

    const { accountNumberLookupReportInfo, setAccountNumberLookupReportInfo } = useContext(accountNumberLookupReportContext);

    const [affiliateSearchCriteria, setAffiliateSearchCriteria] = useState(() => {
        const saved = accountNumberLookupReportInfo.searchCriteria;
        return saved.length >= 1 ? saved : "";
    });
    const [affiliatePage, setAffiliatePage] = useState(0);

    const [reportApiResponse, setReportApiResponse] = useState(() => {
        const saved = accountNumberLookupReportInfo.reportData;
        return saved != null ? saved : {}
    });

    const [reportRecords, setReportRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMorePages, setHasMorePages] = useState(false);

    const getAccountNumberLookupApiReq = useRequest(getAccountNumberLookupApi);
    const downloadAccountNumberLookupReportApiReq = useRequest(downloadAccountNumberLookupReportApi);

    const [pageJustLoaded, setPageJustLoaded] = useState(true);
    const pageProcessing = useRef();

    const handleGenereateReportClick = (e) => {
        setCurrentPage(0);
        pageProcessing.current = true;
        loadUserReportData(1);
        setPageJustLoaded(false);
        setAccountNumberLookupReportInfo({
                ...accountNumberLookupReportInfo,
                toDate: toDate,
                fromDate: fromDate,
                reportHasBeenGenerated: true
        });
    };
    
    const loadUserReportData = (page) => {
        getAccountNumberLookupApiReq.request({ page: (parseInt(page, 0)), toDate:toDate.format('MM-DD-YYYY'),fromDate:fromDate.format('MM-DD-YYYY')}, setReportApiResponse);
    }

    useEffect(() => {
        AddOrUpdateReportRecords(reportApiResponse?.data);
        setCurrentPage(reportApiResponse?.pageInfo?.currentPage);
        setHasMorePages(reportApiResponse?.pageInfo?.totalPages > reportApiResponse?.pageInfo?.currentPage);
        setAccountNumberLookupReportInfo({
            ...accountNumberLookupReportInfo,
            reportData: {
                ...reportApiResponse
            }
        });
        pageProcessing.current = null;
    }, [reportApiResponse]);

    const AddOrUpdateReportRecords = (items) => {
        const respRecords = currentPage === 0 ? [] : reportRecords;
        items?.map((rec) => {
            respRecords.push({ ...rec });
        });
        setReportRecords(respRecords);
    }

    const handleExportToExcelClick = () => {
        downloadAccountNumberLookupReportApiReq.request({ page: 1, toDate:toDate.format('MM-DD-YYYY'), fromDate:fromDate.format('MM-DD-YYYY')},  setReportApiResponse);
    }
    
    const previousDate = useMemo(() => {
        return dayjs().subtract(1, 'day');
    })

    const [fromDate, setFromDate] = useState(() => {
        const saved = accountNumberLookupReportInfo.fromDate;

        if(dayjs(saved).isValid()){
            return saved;
        } else{
            return dayjs().subtract(1, 'day');
        }
    });
    const [toDate, setToDate] = useState(() => {
        const saved = accountNumberLookupReportInfo.toDate;
        
        if(dayjs(saved).isValid()){
            return saved;
        } else{
            return dayjs().subtract(1, 'day');
        }
    });

    const updateFromDate = (fromDate) => {
        setFromDate(fromDate);
    }

    const updateToDate = (toDate) => {
        setToDate(toDate);
    }

    const handleReportRecordMapping = (reportRecords) => {
        if(reportRecords.length >= 1){
            return mapRecordsToTable(reportRecords);
        } else{
            if(pageProcessing.current == null && reportRecords.length == 0 &&
                accountNumberLookupReportInfo.reportHasBeenGenerated){
                return (
                    <Grid item md={12} style={{paddingTop:'20%'}} sx={{minHeight: '50vh'}}>
                        <Grid item md={12}>
                            <ReportMessage >No records found!</ReportMessage> 
                        </Grid>
                    </Grid>
                )
            }  else {
                return (
                    <Grid item md={12} style={{textAlign: 'center', paddingTop:'20%'}} sx={{minHeight: '50vh'}}>
                        <Grid item md={12}>
                            <ReportMessage>Welcome!</ReportMessage> 
                        </Grid>
                        <Grid item md={12}>
                            <ReportMessage>Select Date range to Generate Report</ReportMessage> 
                        </Grid>
                    </Grid>
                ) 
            }
        }
    }

    const mapRecordsToTable = (reportRecords) => {
        return (
            <>
                <Grid item md sm />
                    <Grid item sx={{ marginRight: "2em" }}>
                        <Tooltip arrow title="Export to Excel.">
                            <TableViewOutlinedIcon onClick={handleExportToExcelClick} />
                        </Tooltip>
                    </Grid>
                    <Grid item md={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><ListLabel>Agent Username</ListLabel></TableCell>
                                        <TableCell><ListLabel>Account Number Looked Up</ListLabel></TableCell>
                                        <TableCell><ListLabel>Email</ListLabel></TableCell>
                                        <TableCell><ListLabel>Lookup Date</ListLabel></TableCell>
                                        <TableCell><ListLabel>Order Placed</ListLabel></TableCell>
                                        <TableCell><ListLabel>Mobile Order Id</ListLabel></TableCell>
                                        <TableCell><ListLabel>Order Date</ListLabel></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportRecords?.map((item, idx) => (
                                    <TableRow key={idx + "-" + item.agentId + item.affilaiteName}>
                                        <TableCell>{item.username}</TableCell>
                                        <TableCell>{item.accountNumber ? item.accountNumber : <UnknownLabel>Unavailable</UnknownLabel>}</TableCell>
                                        <TableCell>{item.customerEmail ? item.customerEmail : <UnknownLabel>Unavailable</UnknownLabel>}</TableCell>
                                       
                                        <TableCell>
                                            {item?.lookupDate ? <Tooltip arrow title="Look up date"><span>{new Date(item?.lookupDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span></Tooltip> : <UnknownLabel>Unavailable</UnknownLabel>}
                                        </TableCell>
                                        <TableCell>{item.orderPlaced ? item.orderPlaced : <UnknownLabel>Unavailable</UnknownLabel>}</TableCell>
                                        <TableCell>
                                            {item.mobileOrderId ? item.mobileOrderId : <UnknownLabel>Unavailable</UnknownLabel>}
                                        </TableCell>
                                        <TableCell>
                                            {item?.orderDate ? <Tooltip arrow title="Order date"><span>{new Date(item?.orderDate).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}</span></Tooltip> : <UnknownLabel>Unavailable</UnknownLabel>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box alignItems="center" justifyContent="center" display="flex" sx={{ padding: "2em" }}>
                            {hasMorePages && <Button variant="outlined" text="To see all order details please export the report" sx={{ maxWidth: "380px" }} onClick={handleExportToExcelClick} />}
                        </Box>
                    </Grid>
            </>
            
        )
    }

    return (<>
        <Grid container spacing={1}>
            <Grid item lg md sm>
                <Grid container spacing={1}>
                    <Grid item>
                        <MainTitle>Account Number Lookup Report</MainTitle>
                    </Grid>
                    { handleReportRecordMapping(reportRecords) }
                </Grid>
            </Grid>
            <Drawer open={true} anchor='right' sx={{
                width: "20em",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "20em",
                    boxSizing: 'border-box',
                },
            }}
                variant="permanent">
                <Stack sx={{ marginTop: "auto", marginBottom: "0em", padding: "1em" }} spacing={1} direction="column">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']} sx={{ padding: "0em" }}>
                            <Stack direction='column'>
                                <FormValue>Select Date Range</FormValue>
                                <DatePicker label="From" value={fromDate} onChange={(newValue) => updateFromDate(newValue)} maxDate={toDate} />
                                <br />
                                <DatePicker label="To" value={toDate} onChange={(newValue) => updateToDate(newValue)} maxDate={previousDate} minDate={fromDate}/>
                            </Stack>
                        </DemoContainer>
                    </LocalizationProvider>
                    <Divider />
                    <Button text="Generate Report" onClick={handleGenereateReportClick} />
                </Stack>
            </Drawer>
        </Grid >
    </>);
}

export default AccountNumberLookupReport;