import React, { useEffect, useContext, useState, useRef } from "react";
import { Avatar, Button, Menu, MenuItem, Typography, Grid } from "@mui/material";
import { Stack } from '@mui/system';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { MainTitle } from '../styledComponets/Titles';
import { clearAuthToken, isLoggedIn } from "../../utils/authHelper";
import GlobalContext from "../../context/globalContext/globalContext";
import { defaultBuyflowLaunchInfo, defaultUserInfo } from "../../utils/globalStateHelper";
import PersonIcon from '@mui/icons-material/Person';
import ModalPopup from '../styledComponets/ModalPopup';
import { FormLabel } from '../styledComponets/Labels';
import { renewTokenApi } from "../../api/loginApi";
import useRequest from "../../utils/hooks/useRequest";
import { SESSION_POPOVER } from "../../utils/consts";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import userManagementContext from "../../context/userManagementContext/userManagementContext";
import affiliateManagementContext from "../../context/affiliateManagementContext/affiliateManagementContext";
import affiliateUserReportContext from "../../context/affiliateUserReportContext/affiliateUserReportContext";
import coreOrderReportContext from "../../context/coreOrderReportContext/coreOrderReportContext";
import mobileOrderReportContext from "../../context/mobileOrderReportContext/mobileOrderReportContext";
import accountOrderReportContext from "../../context/accountOrderReportContext/accountNumberLookupReportContext";
import configContext from "../../context/configContext/configContext";

const getSessionTimeoutFromLocalStorage = () => {
    const timeout = localStorage.getItem(SESSION_POPOVER.SESSION_TIMEOUT_KEY);
    if (!timeout) {
        return null;
    } else {
        return parseInt(timeout);
    }
}

const setSessionTimeoutToLocalStorage = (value) => {
    if (value === null) {
        localStorage.removeItem(SESSION_POPOVER.SESSION_TIMEOUT_KEY);
    } else {
        localStorage.setItem(SESSION_POPOVER.SESSION_TIMEOUT_KEY, value);
    }
}

function AccountMenu() {
    const { userInfo, setUserInfo, setLaunchBuyflowInfo } = useContext(GlobalContext);
    const { congifInfo, setConfigInfo, resetConfigInfo } = useContext(configContext);
    const { resetUserManagementInfo } = useContext(userManagementContext);
    const { resetAffiliateManagementInfo } = useContext(affiliateManagementContext);
    const { resetAffiliateReportInfo } = useContext(affiliateUserReportContext);
    const { resetCoreOrderReportInfo } = useContext(coreOrderReportContext);
    const { resetMobileOrderReportInfo } = useContext(mobileOrderReportContext);
    const { resetAccountOrderReportInfo } = useContext(accountOrderReportContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openSessionPopup, setOpenSessionPopup] = React.useState(false);
    const [timerMins, setTimerMins] = useState(3);
    const [timerSecs, setTimerSecs] = useState(0);
    const refreshTokenApiReq = useRequest(renewTokenApi);
    const timerInterval = useRef();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };

    let popupTimer = 0;
    const handleLogout = () => {
        getSessionTimeoutFromLocalStorage(null);
        clearInterval(timerInterval.current);
        timerInterval.current = null;
        clearAuthToken();
        clearContexts();
    }

    const clearContexts = () => {
        setUserInfo({ ...defaultUserInfo });
        setLaunchBuyflowInfo({ ...defaultBuyflowLaunchInfo });
        resetAffiliateReportInfo();
        resetAffiliateManagementInfo();
        resetCoreOrderReportInfo();
        resetMobileOrderReportInfo();
        resetAccountOrderReportInfo();
        resetUserManagementInfo();
        resetConfigInfo();
    }

    const setContextInfo = (userInfo, configInfo) => {
        setUserInfo(userInfo);
        setConfigInfo(configInfo);
    }

    const handleContinue = (e) => {
        refreshTokenApiReq.request(setContextInfo);
        clearInterval(timerInterval.current);
        timerInterval.current = null;
        setSessionTimeoutToLocalStorage(null);
        setOpenSessionPopup(false);
    };

    const sessionBufferTime = (e) => {
        let totalSecs = getSessionTimeoutFromLocalStorage();
        if (totalSecs > 0) {
            totalSecs = totalSecs - 1;
            setSessionTimeoutToLocalStorage(totalSecs);
            setTimerSecs(totalSecs % 60);
            setTimerMins(Math.floor(totalSecs / 60));
        } else {
            handleLogout();
        }
    }

    const sessionPopupNotifierTimeWindow = (e) => {
        if (popupTimer === 0) {
            let tokenExpiryDateTime = new Date(userInfo.expiery * 1000);
            let today = new Date();
            let diffMs = (tokenExpiryDateTime - today);
            let diffMins = (Math.floor(diffMs / 86400000) * 24 * 60) + (Math.floor((diffMs % 86400000) / 3600000) * 60) + Math.round(((diffMs % 86400000) % 3600000) / 60000);
            let sessionPopupTimer = (diffMins - SESSION_POPOVER.TIMEOUT_IN_MIN) * 60 * 1000;
            popupTimer = setTimeout(() => {
                setOpenSessionPopup(true);
                if (getSessionTimeoutFromLocalStorage() === null) {
                    setSessionTimeoutToLocalStorage(SESSION_POPOVER.TIMEOUT_IN_MIN * 60);
                }
                let totalSecs = getSessionTimeoutFromLocalStorage();
                setTimerSecs(totalSecs % 60);
                setTimerMins(Math.floor(totalSecs / 60));
                timerInterval.current = setInterval(sessionBufferTime, 1000);
            }, sessionPopupTimer);
        }
    }

    useEffect(() => {
        if ((isLoggedIn())) {
            if (userInfo.expiery < new Date() / 1000) {
                handleLogout();
            } else {
                if (popupTimer === 0) {
                    sessionPopupNotifierTimeWindow();
                }
            }
        } else {
            setSessionTimeoutToLocalStorage(null);
            clearTimeout(popupTimer);
        }
    }, [userInfo.expiery]);

    return (

        <Stack direction='row'>
            <Avatar sx={{ width: 40, height: 40, backgroundColor: '#fff' }} variant='rounded' >
                <PersonIcon sx={{ color: '#005a84', fontSize: "1.5em" }} />
            </Avatar>
            <Button sx={{ color: '#fff' }}
                id="basic-button"
                onClick={handleClick}>
                <Stack direction='column'>
                    <Stack direction='row'>
                        {`${userInfo.firstName} ${userInfo.lastName}`}
                        <KeyboardArrowDownOutlinedIcon sx={{ color: '#fff' }} />
                    </Stack>
                    <Typography sx={{ marginTop: '-.5em', fontSize: "small", textAlign: "left" }} >
                        {userInfo.userType}
                    </Typography>
                </Stack>
            </Button>
            <Menu sx={{ color: '#fff', textAlign: "right" }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 0.1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <ModalPopup title="Session Timeout Popup" open={openSessionPopup} onClose={handleLogout} enableBackDropClose={false} maxWidth="sm"
                innerComponent={
                    <Grid container spacing={1} >
                        <Grid item sm={12}>
                            <MainTitle>Session Timeout</MainTitle>
                        </Grid>
                        <Grid item sm={12}>
                            <AccessTimeIcon sx={{ color: '#005a84', fontSize:"1.2em", marginBottom: "-0.3em", marginRight: "0.3em" }} />
                            <FormLabel>Your online session willl expire due to inactivity in</FormLabel>
                        </Grid>
                        <Grid item sm={12}>
                            <FormLabel sx={{fontSize:"2.0em !important", color: '#005a84',  marginLeft: "5.5em"}}>{timerMins} mins {timerSecs} Secs</FormLabel>
                        </Grid>
                        <Grid item sm={12}>
                            <FormLabel >Please click "Continue" to keep working or click "Logout" to end your session now</FormLabel>
                        </Grid>
                        <Grid item sm={12}>
                            <Stack direction='row' sm={5} columnGap={2}>
                                <Button variant="contained" size="large" onClick={handleContinue}>Continue</Button>
                                <Button variant="outlined" size="large" onClick={handleLogout} >Logout</Button>
                            </Stack>
                        </Grid> 
                    </Grid>
                } />
        </Stack>
    );
}
export default AccountMenu;