/* istanbul ignore file */
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import AccountNumberLookupReportContext, {getDefaultContext} from './accountNumberLookupReportContext';
import accountNumberLookupReportReducer from './accountNumberLookupReportReducer';
import { RESET_ACCOUNT_NUMBER_LOOKUP_CONTEXT, SET_ACCOUNT_NUMBER_LOOKUP_REPORT_INFO } from './accountNumberLookupReportActionTypes';


const AccountNumberLookupReportState = props => {
  const [accountNumberLookupReportContext, dispatch] = useReducer(accountNumberLookupReportReducer, getDefaultContext());
  const setAccountNumberLookupReportInfo = accountNumberLookupReportInfo => dispatch({ type: SET_ACCOUNT_NUMBER_LOOKUP_REPORT_INFO, payload: accountNumberLookupReportInfo });
  const resetAccountNumberLookupReportInfo = () => dispatch({ type: RESET_ACCOUNT_NUMBER_LOOKUP_CONTEXT, payload: null})

  return (
    <AccountNumberLookupReportContext.Provider
      value={{
        ...accountNumberLookupReportContext,
        setAccountNumberLookupReportInfo,
        resetAccountNumberLookupReportInfo
      }}
    >
      {props.children}
    </AccountNumberLookupReportContext.Provider>
  );
};
AccountNumberLookupReportState.propTypes = {
  children: PropTypes.object,
};

export default AccountNumberLookupReportState;
