import { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Header from "./components/header/header";
import { ROUTES } from "./utils/consts";
import Login from "./pages/login";
import Home from "./pages/home";
import Users from "./pages/users";
import Settings from "./pages/settings";
import { Backdrop, CircularProgress, GlobalStyles, Grid } from '@mui/material';
import ForgotPassword from './pages/forgetPassword';
import ChangePassword from './components/accounts/changePassword/ChangePassword';
import SecurityQuestion from './components/accounts/securityQuestion/SecurityQuestion';
import Affiliates from './pages/affiliates';
import Reports from './pages/reports';
import globalContext from './context/globalContext/globalContext';
import AffiliateUserReport from './components/reports/AffiliateUserReport';
import CoreOrderReport from './components/reports/CoreOrderReport';
import MobileOrderReport from './components/reports/MobileOrderReport';
import AccountNumberLookupReport from './components/reports/AccountNumberLookupReport';
import DataContexts from './context/DataContexts';
import axios from 'axios';
import { clearAuthToken, isLoggedIn } from './utils/authHelper';
import { HTTPResponseCodes } from './utils/httpResponseCodeHelper';
import useGlobalMessenger from './utils/hooks/useGlobalMessenger';
import AccountNumberSearch from './pages/accountNumberSearch';
import AddressSearch from './pages/addressSearch';

function AppProgressBar() {
  const { openRequests } = useContext(globalContext);
  return (<>
    {openRequests > 0 &&
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    }
  </>);
}

function AppRequestErrorHandler(props) {
  const { setGlobalMessage } = useGlobalMessenger();

  const handleRequestError = (error) => {

    var errorCode = error.response.status;

    if (errorCode === 401) {
      if (!error?.request?.responseURL.includes("/auth")) {
        window.location.href = '/login'
        clearAuthToken();
        return;
      }
      else {
        return;
      }
    }

    var errorText = "";

    if (error.response.data?.errors?.message) {
      errorText = error.response.data.errors.message;
    } else if ((errorCode in HTTPResponseCodes)) {
      errorText = HTTPResponseCodes[errorCode];
    } else {
      errorText = "Server Unreachable";
    }

    setGlobalMessage({ text: errorText, variant: "error" });
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      handleRequestError(error);

      return Promise.reject(error);
    });

  return (<>{props.children}</>)
}

function AppRequestTokenHandler(props){
  const navigate = useNavigate();
  const allowedURLs = ["auth", "actuator"];

  axios.interceptors.request.use(function (request){
    if(isLoggedIn() || allowedURLs.some(url => request.url.includes(url))){
      return request;
    } else{
      navigate(ROUTES.LOGIN_ROUTE);
    }
  });

  return (<>{props.children}</>)
}

function App() {
  return (
    <>
      <AppRequestErrorHandler>
        <DataContexts>
          <Grid container>
            <Grid item md={12} sm={12}>
              <Header />
            </Grid>
            <Grid item md={12} sm={12} sx={{ margin: '0 4em 0 6em' }}>
              <GlobalStyles styles={{ td: { fontFamily: "SpectrumSans !Important", color: '#005a84 !Important' } }} />

              <Router>
                <AppRequestTokenHandler>
                  <Routes>
                    <Route path={ROUTES.DEFAULT_ROUTE} element={<Login />} />
                    <Route path={ROUTES.LOGIN_ROUTE} element={<Login />} />
                    <Route path={ROUTES.HOME_ROUTE} element={<Home />} />
                    <Route path={ROUTES.USERS_ROUTE} element={<Users />} />
                    <Route path={ROUTES.ACCOUNT_NUMBER_SEARCH_ROUTE} element={<AccountNumberSearch />} />
                    <Route path={ROUTES.ADDRESS_SEARCH_ROUTE} element={<AddressSearch />} />
                    <Route path={ROUTES.AFFILIATES_ROUTE} element={<Affiliates />} />
                    <Route path={ROUTES.REPORTS_ROUTE} element={<Reports />} >
                      <Route path={ROUTES.AFFILIATE_USER_REPORT_ROUTE} element={<AffiliateUserReport />} />
                      <Route path={ROUTES.CORE_ORDER_REPORT_ROUTE} element={<CoreOrderReport />} />
                      <Route path={ROUTES.MOBILE_ORDER_REPORT_ROUTE} element={<MobileOrderReport />} />
                      <Route path={ROUTES.ACCOUNT_NUMBER_LOOKUP_REPORT_ROUTE} element={<AccountNumberLookupReport />} />
                    </Route>
                    <Route path={ROUTES.SETTINGS_ROUTE} element={<Settings />} />
                    <Route path={ROUTES.FORGETPASSWORD_ROUTE} element={<ForgotPassword />} />
                    <Route path={ROUTES.CHANGE_PASSWORD_ROUTE} element={<ChangePassword />} />
                    <Route path={ROUTES.SECURITY_QUESTION_ROUTE} element={<SecurityQuestion />} />
                    <Route path="/" element={<Navigate to={ROUTES.DEFAULT_ROUTE} />} />
                    <Route path="*" element={<Navigate to={ROUTES.LOGIN_ROUTE} />} />
                  </Routes>
                </AppRequestTokenHandler>
              </Router>

            </Grid>
            <AppProgressBar />
          </Grid>
        </DataContexts>
        
      </AppRequestErrorHandler>
    </>
  );
}

export default App;