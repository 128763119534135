import axios from "axios";
import { getApiOptions } from "../utils/apiHelpers";
import URLs from "../utils/urls";


export async function getUserAffiliatesReportApi(params, setResponse) {
    const endpointUrl = `${URLs.REPORTS}/affiliateusers`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        params: params
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function downloadUserAffiliatesReportApi(params) {
    const endpointUrl = `${URLs.REPORTS}/affiliateusers/export/xlsx`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        responseType: 'arraybuffer',
        params: params
    };

    try {
        const response = await axios(ops);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Affiliate User Report - ${new Date(Date.now()).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}.xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        // if (error?.response?.data?.errors) {
        //     setResponse(error?.response?.data);
        // } else {
        //     setResponse(error);
        // }
    }
}

export async function getCoreOrdersReportApi(params, setResponse) {
    const endpointUrl = `${URLs.REPORTS}/core`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        params: params
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function downloadCoreOrdersReportApi(params) {
    const endpointUrl = `${URLs.REPORTS}/core/export/xlsx`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        responseType: 'arraybuffer',
        params: params
    };

    try {
        const response = await axios(ops);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Core Order Report - ${new Date(Date.now()).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}.xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        // if (error?.response?.data?.errors) {
        //     setResponse(error?.response?.data);
        // } else {
        //     setResponse(error);
        // }
    }
}


export async function getMobileOrdersReportApi(params, setResponse) {
    const endpointUrl = `${URLs.REPORTS}/mobile`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        params: params
    };

    try {
        // setResponse({ isLoading: true });
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function downloadMobileOrdersReportApi(params) {
    const endpointUrl = `${URLs.REPORTS}/mobile/export/xlsx`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        responseType: 'arraybuffer',
        params: params
    };
    
    try {
        const response = await axios(ops);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Mobile Order Report - ${new Date(Date.now()).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}.xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        // if (error?.response?.data?.errors) {
        //     setResponse(error?.response?.data);
        // } else {
        //     setResponse(error);
        // }
    }
}

export async function getAccountNumberLookupApi(params, setResponse) {
    const endpointUrl = `${URLs.REPORTS}/accountlookuporders`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        params: params
    };

    try {
        const response = await axios(ops);
        setResponse(response?.data);
    } catch (error) {
        if (error?.response?.data?.errors) {
            setResponse(error?.response?.data);
        } else {
            setResponse(error);
        }
    }
}

export async function downloadAccountNumberLookupReportApi(params) {
    const endpointUrl = `${URLs.REPORTS}/accountlookuporders/export/xlsx`
    const ops = {
        method: 'get',
        url: endpointUrl,
        ...getApiOptions(),
        responseType: 'arraybuffer',
        params: params
    };
    
    try {
        const response = await axios(ops);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Account number lookup Report - ${new Date(Date.now()).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}.xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
    }
}